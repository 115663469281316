.image_body {
    .modalMainDiv {
        background-color: #fff;
        height: 70%;
        width: 80%;
    }
}
.data_body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // text-align: center;
    // background-color: #fff;

    height: 500px;
    width: 700px;

    .profile-card {
        position: relative;
        background-color: #ffffff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        width: 700px;
        height: 540px;
        margin: 0px auto;
        padding: 0px 20px 20px;
        border-radius: 8px;
        overflow-y: auto;

        .header{
            position: sticky;
            top: 0;
            text-align: center;
            width: 100%;
            z-index: 999;
            display: flex;
            align-items: center;
            // .profile-name{
            // }
        }
       
        .header_close {
            position: absolute;
            top: 15px;
            right: 15px;
            i {
              color: #000000;
            // background: #000;
              font-size: 26px;
            }
          }
    }
}
.viewButton {
    text-align: center;
    .eye-icon {
        &:hover {
            color: black;
        }
    }
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
.header_close {
    position: relative;
     display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0 12px 000000;
    &:hover {
        cursor: pointer;
    }
}

table {
    width: 100%;
    margin-bottom: 20px;
}

th,
td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
}

th {
    text-align: left;
    color: #666666;
    width: 155px;
}

td {
    color: #333333;
}

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: block;
}

.profile-name {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333333;
    text-align: center;
}

.profile-email {
    color: #666666;
    text-align: center;
    margin-bottom: 20px;
}
.eye-icon {
    &:hover {
        cursor: pointer;
        color: black;
    }
}
.image_bodyy{
    min-width: 600px;
    .image-div{
        padding: 20px 0px 20px;
    }
    .carousel.carousel-slider{
        display: flex;
        button{
            margin: 20px 0px;
        }
    }
}