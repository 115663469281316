.alignment-class {
  @media (max-width: 2000px) {
    display: flex;
    justify-content: center;
    gap: 150px;
  }
  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
}

.mobile-view-alignment-main {
  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
}

.date-picker {
  @media (max-width: 576px) {
    margin-left: -10px;
  }
}
