.tab-hidden-area {
  max-height: 0px;

  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.tab-show-area {
  max-height: 200px;
  overflow: hidden;
  transition: 0.3s ease-in-out;

}

.menu-item:hover .menu-link .menu-icon .inactive-svg {
  display: none;
}

.menu-item:hover .menu-link .menu-icon .active-svg {
  display: block ;
}