.input {
  .new-map-div {
    background-color: #f3f6f9;
    border-color: #c4c4c4;
    color: #3f4254;
  }
}
.icon-input-alignment {
  border: 1px solid #c4c4c4;
  height: 45px;
  background-color: #f3f6f9;
  color: #3f4254;
  border-radius: 5px;
  padding: 0px 10px;
  outline: none;
  width: 100%;
}
