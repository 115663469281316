.select {
    align-items: center;
    cursor: default;

    display: flex;

    flex-wrap: wrap;

    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;

    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}
 .option {
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
}
.mainDivSelect {
    display: flex;
    // margin-left: 10px;
    // gap: 10px;
    min-width: 150px;
}
.Apply-button {
    background-color: #41624c !important;
    border-color: #41624c !important;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px !important;
    border-radius: 0.5rem;
}
.css-1nmdiq5-menu{
    z-index: 9999;
}
.css-1nmdiq5-menu > div{
    z-index: 99999999999;
}

.css-1nmdiq5-menu{
    z-index: 9999 !important;
}
.reactDatePicker{
    z-index: 9;
}
.Date-Dropdown{
    position: relative;
    .cross-icon{
        // color: #000;
        position: absolute;
        top:13px;
        right: 51px;
    }
}

.Datepicker {
    flex-wrap: nowrap;

    @media (max-width:1600px) {
        flex-wrap: wrap;
        gap: 10px;
        
        .react-datepicker__input-container {
            margin: 0 !important;
        }
    }
    @media (max-width:1500px) {
        flex-wrap: wrap;
        gap: 10px;
        
        .react-datepicker__input-container {
            margin: 0 !important;
        }
    }


    @media (max-width:1400px) {
        flex-wrap: wrap;
        gap: 10px;
        
        .react-datepicker__input-container {
            margin: 0 !important;
        }
    }

    @media (max-width:576px) {
        .react-datepicker__input-container {
            margin: 0 0 0 0;
        }
    }

  
}