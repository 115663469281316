// Variables for colors
$green-color: #000;
$blue-color: #007bff;
$yellow-color: #ffc107;
$text-color: #fff;

// Base styles for the markers button
.markersButton {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  color: $text-color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  box-shadow: 0 4px 6px #fff;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  &.green-pin {
    background-color: $green-color;
    border: 3px solid darken($green-color, 10%);
  }

  &.blue-yellow-pin {
    background: linear-gradient(to bottom, $blue-color, $yellow-color);
    border: 3px solid $blue-color;
  }
}
