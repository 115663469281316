.notification-icon-akignment {
  position: relative;
  .notification-number {
    position: absolute;
    top: -10px;
    right: -3px;
    height: 17px;
    width: 15px;
    div {
      background-color: #ff8181;
      color: white;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-family: Inter;
      font-size: 10px;
      font-weight: 600;
      line-height: 12.1px;
      letter-spacing: 0.005em;
      text-align: center;
    }
  }
}

.main-navbar-alignment {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .item-xxx {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .justify-content-design {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    .notification-icon-akignment {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .navbar-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      border: 2px solid #e5e5e5;
      border-radius: 5px;
      padding: 6px 11px;
    }
    .navbar-alignment-new{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}

.first-char-design {
  background: #006037;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: -32px;
  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #f4f6f9;
  }
}
