.mitteilungen-all-contnet-alignment {
  border-radius: 6px;
  background: #fff;
  padding: 11px 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .notification-header-design {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2px;
    margin-top: 10px;
    .text-design {
      p {
        font-size: 28px;
        font-weight: 500;
        margin-left: 40px;
      }
    }

    .add-notification-button {
      margin-right: 40px;
      display: flex;
      gap: 20px;
      button {
        cursor: pointer;
        border: none;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        padding: 15px 25px;
        border-radius: 4px;
        background: #006037;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        cursor: pointer;
      }
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 327px 1fr;
    gap: 27px;

    .grid-items:nth-child(1) {
      border-right: 1px solid #f3f3f3;
      height: calc(100vh - 300px);
      overflow: auto;
      position: sticky;
      top: 0;
    }
    .grid-items:nth-child(2) {
      height: calc(100vh - 215px);
      overflow: auto;
    }
    .all-list-alignment {
      padding: 36px 40px 0 0;
      p {
        margin: 0 0 36px 0;
        color: #8b8b8b;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0.3px;
      }
    }
    .user-data-alignment {
      padding-top: 20px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .user-tag-design {
        p {
          background-color: #006037;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          padding: 9px 10px;
          border-radius: 5px;
        }
      }
    }

    .neues-all-contetent-alignment {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding: 0 0 32px 0;
      border-bottom: 1px solid #f3f3f3;
      gap: 21px;
      background: white;
      position: sticky;
      top: 0;
      width: 100%;
      .add-notification-alignment {
        display: flex;
        gap: 480px;
        justify-content: space-between;

        .new-item{
          display: flex;
          gap: 30px;
        }
       button{
        cursor: pointer;
       }
      }

      span {
        display: block;
        color: #000;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
        word-break: break-all;
      }

      button {
        border: none;
        color: #fff;
        font-size: 12px;
        font-style: normal;
        padding: 6px 8px;
        border-radius: 4px;
        background: #006037;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        cursor: pointer;
      }

      button{

      }
    }

    .grid-items {
      .sub-box {
        padding: 20px;
        border-bottom: 1px solid #f3f3f3;
        display: grid;
        grid-template-columns: 1fr 26px;
        cursor: pointer;
        gap: 26px;
        position: relative;
        transition: 0.3s ease-in-out;

        &:hover {
          background: #f8f7fe;
        }

        &::before {
          width: 1px;
          background: #de6734;
          height: 75px;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          position: absolute;
          content: "";
        }

        p {
          color: #acacac;
          font-size: 17px;
          font-style: normal;
          margin: 0 0 18px 0;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.34px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: normal;
          word-break: break-all;
          text-overflow: ellipsis;
        }

        span {
          padding: 0 0 6px 0;
          display: block;
          color: #6a6a6a;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.28px;
        }

        h6 {
          color: #c8c7c7;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.28px;
        }
      }
    }
  }
}
.active {
  background: #f8f7fe;
}
.loader-design {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
