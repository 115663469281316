.modal-image {
  max-width: 600px;
  max-height: 600px;
}
.filter-icon {
  position: relative;
  margin-left: 4rem;
  text-align: center;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  // max-height: 100px;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  a:hover {
    background-color: #f1f1f1;
  }
}
.css-1nmdiq5-menu {
  overflow-y: auto;
  z-index: 9999;
}
.css-1nmdiq5-menu > div {
  z-index: 9999;
  max-height: 150px;
}
.react-datepicker__input-container {
  margin-left: 1rem;
  @media (max-width: 576px) {
    margin-left: 0;
  }
}
.react-datepicker__input-container > input {
  &:hover {
    cursor: pointer;
  }
  align-items: center;
  cursor: default;
  margin-right: 1rem;
  display: flex;

  flex-wrap: wrap;

  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;

  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.SendButton {
  background-color: #41624c !important;
  border-color: #41624c !important;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  border-radius: 0.5rem;
}

.buttonDiv {
  // margin-left: 1.4rem;
  width: 100%;
  display: flex;
  margin: 1rem 1rem 0rem 0rem;
  justify-content: flex-end;
}
.css-13cymwt-control {
  max-height: 66px;
  min-height: unset;
  overflow: auto;
}
input[type="checkbox"] {
  accent-color: #006037;
  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;
}
.profileImag {
  color: black;
}
.profileImag-pp {
  background-color: #f6eadf;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #e8a56a;
}
.profileImag-ppp {
  background-color: #e5f3ec;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #006037;
}
.profileImag-pppp {
  background-color: #f6dfdf;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #d12424;
}

.row {
  @media (max-width: 576px) {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
  }
}

.mobile-view-alignment-main {
  gap: 20px;

  .mainDivSelect {
    @media (max-width: 576px) {
      min-width: 100%;
      width: 100%;
    }
  }

  .css-14mj9xh-control,
  .css-a33qwv-control {
    margin: 0;
  }
}

.mobile-view-alignment {
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px !important;
  }

  .mobile-view {
    @media (max-width: 576px) {
      margin: 0 0 0 -42px !important;
      padding: 0 0 0 0 !important;
    }
  }

  .mobile-view2 {
    @media (max-width: 576px) {
      margin: 0 0 0 -32px !important;
      padding: 0 0 0 0 !important;
    }
  }
  .mobile-view3 {
    @media (max-width: 576px) {
      margin: 0 0 0 -32px !important;
      padding: 0 0 0 0 !important;
    }
  }
  .mobile-view4 {
    @media (max-width: 576px) {
      margin: 0 0 0 -12px !important;
      padding: 0 0 0 0 !important;
    }
  }
}

.date-filter-mobile-p {
  h2 {
    @media (max-width: 576px) {
      margin-left: -15px;
    }
  }
  @media (max-width: 576px) {
    display: grid !important;
  }
  .mobile {
    display: none;
    @media (max-width: 576px) {
      padding-top: 5px;
      display: block;
      margin-left: -10px !important;
    }
  }
}

.web {
  @media (max-width: 1500px) {
    margin-top: -30px;
  }

  @media (max-width: 576px) {
    display: none;
  }
}

.buttonDiv {
  @media (max-width: 576px) {
    display: flex;
    justify-content: flex-start !important;
  }
}

.css-58pfae-control {
  @media (max-width: 576px) {
    width: auto !important;
  }
}

.modal-content {
  @media (max-width: 576px) {
    height: 654px;
    overflow-x: hidden;
  }
}

.new-model-conent-sye {
  .modal-body {
    height: 530px;
    overflow: auto;
  }
}

.modal-open .modal {
  @media (max-width: 576px) {
    display: flex !important;
    height: 100vh;
    width: 100vw;
  }
}

// .modal-content {
//   @media (max-width: 576px) {
//     width: 84% !important;
//   }

//   .modal-content {
//     @media (max-width: 576px) {
//       width: 100% !important;
//     }
//   }
// }

.modal-backdrop {
  @media (max-width: 576px) {
    width: 100% !important;
    height: 100% !important;
  }
}

.modal-body {
  .col-4 {
    padding: 0 2px !important;
  }

  .row {
    @media (max-width: 576px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 450px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }

    .col-4 {
      @media (max-width: 576px) {
        max-width: none !important;
      }
    }
  }

  .profile-child-box {
    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

/* custom-datepicker.css */
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected {
  background-color: #41624c !important;
  color: white !important;
  border: 1px solid #41624c !important;
}

.react-datepicker__day--selected:hover {
  background-color: #41624c !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  background-color: #41624c !important;
  color: white !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-start:hover,
.react-datepicker__day--range-end,
.react-datepicker__day--range-end:hover {
  background-color: #41624c !important;
  color: white !important;
}

/* General hover styles for all days */
.react-datepicker__day:hover {
  background-color: #41624c !important;
  color: white !important;
}

/* Hover state during range selection */
.react-datepicker__day--in-selecting-range:hover {
  background-color: #41624c !important;
  color: white !important;
}

/* Hover state for days within the range selection process */
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-start:hover,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--selecting-range-end:hover {
  background-color: #41624c !important;
  color: white !important;
}

/* Hover state during range selection */
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:hover {
  background-color: #41624c !important;
  color: white !important;
}

.new-mobile-table {
  display: none;
  @media (max-width: 576px) {
    display: block;
  }
}

.web-table {
  display: block;
  @media (max-width: 576px) {
    display: none;
  }
}

.Modelmobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: #fff3;
  backdrop-filter: blur(15px);
  padding: 20px;

  @media (max-width: 500px) {
    display: flex;
  }

  .modelinnercontent {
    background-color: #fff;
    height: 700px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .closetitle {
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
      padding: 10px;

      .Closeicon {
        i {
          font-size: 30px;
        }
      }
    }

    .table-bordered td {
      width: 200px;
      max-width: 200px;
      min-width: 200px;
      word-break: break-word;
    }
  }
}

.new-status-datss {
  white-space: break-spaces;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.wattfox-header-alignment {
  align-items: start;
  // justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
}

.mobile-view-alignment-main-parent {
  max-width: inherit !important;
  flex: 1 !important;
  @media (max-width: 1200px) {
    width: 100% !important;
    max-width: 100% !important;
  }

  @media (max-width: 576px) {
    padding: 0 !important;
  }

  .mobile-view-alignment-main {
    @media (max-width: 1200px) {
      margin: 0 !important;
    }

    @media (max-width: 576px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .css-b62m3t-container {
        width: 100% !important;
      }

      .css-1n52tgk-control {
        min-width: 100% !important;
      }

      .css-14mj9xh-control {
        min-width: 100% !important;
      }

      .css-a33qwv-control {
        min-width: 100% !important;
      }

      .mobile-view-serch {
        grid-column: span 2;
      }
    }
  }
}

.mobile-view-alignment-main-parent-title {
  @media (max-width: 1200px) {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }
}
