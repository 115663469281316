.change-password-icon {
  &:hover {
    color: #578567;
    cursor: pointer;
  }

  display: block;
  font-size: 36px;
  text-align: center;
  color: #41624c;
}

.dropdown-align {
  max-width: 300px;
  min-width: 100px;
  max-height: 300px;
  // overflow: auto;
}

.pencilIcon {
  &:hover {
    cursor: pointer;
  }

  // font-size: 26px;
  color: #41624c;
}

.deleteIcon {
  color: #41624c;
}

.eyeIconContainer {
  position: relative;

  .eyeIcon {
    &:hover {
      cursor: pointer;
    }

    position: absolute;
    top: 12px;
    right: 10px;
  }
}

.statusbutton {
  height: calc(0.5em + 1.65rem + 2px);
  width: calc(3.5em + 1.65rem + 2px);
}

.success {
  background-color: #4caf50 !important;
  color: white !important;
}

.danger {
  background-color: #f44336 !important;
  color: white !important;
}

.sucessfull {
  background-color: #41624c !important;
  color: white !important;
}

.custom-input::-webkit-search-cancel-button {
  cursor: pointer;
}

.new-radiobutton {
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  accent-color: #41624c;

  @media (max-width: 576px) {
    margin-left: 13px;
  }
}

.radio-button-des {
  display: flex;
  align-items: center;
}

.css-58pfae-control {
  width: 100%;
}

.profile-child-box {
  margin: 10px 0 0 0;
  background: #f9f9f9;
  padding: 12px;
  display: grid;
  grid-template-columns: 154px 200px;
  gap: 0;
  position: relative;
  text-wrap: wrap;

  .imagebox {
    background-color: #1f2b26;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .liner {
      width: 4px;
      height: 100%;
      background: #41624c;
      position: absolute;
      top: 0;
      left: 0;

      &::before {
        position: absolute;
        width: 4px;
        height: 20px;
        background-color: #fff;
        bottom: 0;
        left: 0;
        content: "";
      }
    }

    .relative-section {
      position: relative;
      background-color: #fff;
    }

    .right-sider {
      background: #41624c;
      width: 18px;
      top: 50%;
      transform: translateY(-50%);
      right: -10px;
      border-radius: 99px;
      padding: 10px 0;
      position: absolute;

      a:last-child {
        padding: 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 8px;

        img {
          width: 10px;
        }
      }
    }

    p {
      text-align: center;
      color: #fff;
      font-weight: 600;
      margin: 10px 0 2px 0;
      font-size: 12px;
    }

    span {
      font-size: 10px;
      display: block;
      text-align: center;
      color: #fff;
      font-weight: 400;
    }

    .profile-img-style {
      max-width: 84px;
    }
  }

  .text-box {
    padding: 12px 18px;
    background-color: #fff;
    // width: 270px;

    .text-grid {
      display: grid;
      grid-template-columns: 60px 1fr;
      padding: 0 0 5px 0;
      gap: 3px;

      p {
        color: #848484;
        margin: 0;
        word-break: break-all;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        line-height: normal;
      }

      span {
        display: block;
        color: #848484;
        margin: 0;
        word-break: break-all;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .child-log {
      padding: 0 0 25px 0;

      img {
        width: 100px;
      }
    }
  }
}

.image-position {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  top: 840px;
  right: 379px;
}

.filter-user {
  .dropdown-align {
    width: 150px;
  }
}
.filter-user {
  .dropdown-align-pp {
    width: 170px;
  }
}
.css-13cymwt-control {
  &:hover {
    cursor: pointer;
  }
}

.filter-user {
  > div {
    > div {
      cursor: pointer;
    }
  }
}

.row-alignment-top {
  justify-content: space-around !important;
  @media (max-width: 1500px) {
    justify-content: start !important;
  }

  @media (max-width: 1024px) {
    justify-content: space-around;
  }
}

.rdt_TableHead {
  top: -1px !important;
}
.filter-user {
  @media (max-width: 576px) {
    display: none;
  }
}

.filter-user-sc {
  display: none;

  .css-14mj9xh-control {
    margin: 0 0 0 12px !important;
  }

  @media (max-width: 576px) {
    display: block !important;
  }
}

.Vertriebspartner-alignment {
  @media (max-width: 2300px) {
    width: auto !important;
    max-width: max-content !important;
    min-width: auto !important;
  }
  @media (min-width: 1700px) {
    margin-left: -60px !important;
  }
  @media (max-width: 1600px) {
    margin-left: -140px !important;
    width: auto !important;
    max-width: max-content !important;
    min-width: auto !important;
  }

  @media (max-width: 1500px) {
    margin-left: 0px !important;
    width: auto !important;
    max-width: max-content !important;
    min-width: auto !important;
  }
  @media (max-width: 576px) {
    margin-left: -5px !important;
    width: auto !important;
    max-width: max-content !important;
    min-width: auto !important;
  }
}

.suche-button-input-flx-main {
  @media (max-width: 2300px) {
    width: auto !important;
    max-width: max-content !important;
    min-width: auto !important;
  }
  @media (max-width: 1600px) {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    margin: 10px 0 0 0;
  }
  @media (max-width: 576px) {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin: 10px 0 0 0 !important;
  }

  .col {
    @media (max-width: 576px) {
      padding: 0 5px 0 13px !important;
    }
  }

  .filter-button-mobile {
    display: block;

    @media (max-width: 576px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .cusbutton-moblie {
        @media (max-width: 576px) {
          display: flex;
          justify-content: end;
          align-items: center;
        }
      }
      .css-14mj9xh-control {
        @media (max-width: 576px) {
          margin: 0 0 0 13px !important;
        }
      }
    }
    .filter-user {
      display: none;
      margin: 0 !important;
      @media (max-width: 576px) {
        display: block !important;
      }
    }
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .dropdown-align .react-select__dropdown-indicator {
      pointer-events: auto;
    }
  }
}
.new-picker {
  margin-right: 40px !important;
  padding-left: 20px;
}

.react-datepicker-wrapper {
  position: relative;
  @media (max-width: 1500px) {
    position: relative;
  }
}
.icons-design {
  position: absolute;
  top: 10px;
  right: 40px;

  @media (max-width: 1500px) {
    position: absolute;
    top: 10px;
    right: -35px;
  }
}
.new-head-design {
  h2 {
    margin-left: -20px !important;
  }
}

.newlgclass {
  margin: 0;
  @media (max-width: 576px) {
    margin-left: -10px;
  }
}

.checkbox-aligment-main-mobile {
  display: none;
  gap: 20px;
  margin: 10px 0 0 0;
  align-items: center;

  @media (max-width: 576px) {
    display: flex;
  }
}

.checkbox-aligment-main-web {
  @media (max-width: 576px) {
    display: none !important;
  }
}

.modal-steurnummer-alignment-mobile {
  @media (max-width: 576px) {
    padding: 0 !important;
  }
}
