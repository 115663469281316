.bulkUploadImageMain {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  max-width: 700px;
  background-color: #fff;

  @media (max-width: 1440px) {
    padding: 50px 60px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    max-width: 500px;
    background-color: #fff;
  }
  @media (max-width: 576px) {
    padding: 23px 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    max-width: 500px;
    background-color: #fff;
  }

  h1 {
    color: #41624c;
    font-size: 26px;
    @media (max-width: 576px) {
      font-size: 22px;
    }
  }
  h2 {
    color: rgb(28, 0, 54);
    font-size: 26px;
  }
  .dragAndDrop {
    border-radius: 10px;
    background-color: rgb(219, 213, 213, 0.2);
    height: 150px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add box shadow here */
    cursor: pointer;

    @media (max-width: 1440px) {
      border-radius: 10px;
      background-color: rgb(219, 213, 213, 0.2);
      height: 160px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add box shadow here */
      cursor: pointer;
    }
    @media (max-width: 576px) {
      border-radius: 10px;
      background-color: rgb(219, 213, 213, 0.2);
      height: 130px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add box shadow here */
      cursor: pointer;
    }

    p {
      color: rgb(28, 0, 54);
      opacity: 0.5;
    }
    .draganddrop-image-alignment {
      span {
        font-size: 20px;
        color: red;
        display: block;
        margin-top: 56px;
        justify-content: center;
        align-items: center;
      }
    }

    .image-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0 20px 0;
      img {
        width: 50px;
        height: 50px;
      }

      p {
        display: flex;
        justify-content: center;
      }
    }
  }

  p {
    font-size: 22px;
    max-width: 650px;
    a {
      cursor: pointer;
      font-weight: bold;
    }
  }
  .button-alignment-modal {
    display: flex;
    justify-content: center;
    gap: 10px;

    .upload-button {
      padding: 10px 40px;
      background-color: #41624c;
      color: white;
      font-size: 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      @media (max-width: 576px) {
        padding: 10px 30px;
        font-size: 18px;
      }
    }
    .upload-buttons {
      padding: 10px 40px;
      background-color: #7baa8b;
      color: white;
      font-size: 20px;
      border: none;
      border-radius: 5px;
      cursor: no-drop;
      @media (max-width: 576px) {
        padding: 10px 30px;
        font-size: 18px;
      }
    }
  }
  .errors {
    color: red;
    font-size: 18px;
  }
}
