.notizen-plus-div {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 0 13px 0;

  p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
    color: #4d4d4d;
    margin: 0;
  }

  svg {
    margin: 5px 5px;
  }
}
.notizen-plus-div-sc {
  display: flex;
  justify-content: start;
  align-items: end;
  margin: 0 0 13px 0;
  position: relative;

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
  }
  .notizen-plus-div-sc-model-background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000099;
    backdrop-filter: blur(6px);
    width: 100%;
    height: 100%;
    z-index: 99;
    .notizen-plus-div-sc-model-main {
      width: 300px;
      height: 220px;
      background-color: #e3e3e3;
      border: 1px solid #4d4d4d;
      border-radius: 12px;
      overflow: hidden;
      padding: 10px;
      z-index: 9;

      .notizen-plus-div-sc-model-main-icon {
        display: flex;
        justify-content: space-between;

        label {
          font-family: "Inter", sans-serif;
          font-size: 12px;
          font-weight: 600;
          line-height: 14.52px;
          text-align: left;
          color: #4d4d4d;
        }

        i {
          font-size: 16px;
        }
      }

      textarea {
        width: 100%;
        height: 130px;
        resize: none;
        border: 1px solid #e1e1e1;
        padding: 10px 10px;
        background-color: #fff;
        outline: none !important;
        font-family: "Inter", sans-serif;
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        text-align: left;
        color: #848484;
        border-radius: 8px;
        outline: none;

        @media (max-width: 576px) {
          line-height: 20px;
        }
      }

      .footer-button-alignment {
        display: flex;
        justify-content: end;
        margin: 12px 0 0 0;

        button {
          border: none;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 7px 10px;
          background-color: #006037;
          border-radius: 5px;
          color: #fff;
          font-family: "Inter", sans-serif;
          font-size: 12px;
          font-weight: 700;
          line-height: 14.52px;
          text-align: left;
          cursor: pointer;
        }
      }
    }
  }

  .notizen-plus-div-sc-alig {
    p {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 25.94px;
      text-align: left;
      color: #4d4d4d;
      margin: 0 0 5px 0;
      display: flex;
      gap: 10px;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }
    .svg-ic-align {
      display: flex;
      gap: 10px;
      span {
        color: #4d4d4d;
        font-weight: 500;
        font-size: 11px;
        line-height: 13.31px;
        text-align: left;
      }
    }

    // svg {
    //   margin: 4px 4px 8px 4px;
    // }
  }
}

.upload-icon-size-change {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.main-relative {
  position: relative;
}

.all-upload-data-details {
  padding: 10px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  .upload-data-grid {
    display: grid;
    grid-template-columns: 1fr 30px;
    padding: 3px;
    gap: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    align-items: center;
    p {
      font-size: 7px;
      color: #777;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 500;
      margin: 0;
    }

    .two-icon-alignment {
      display: flex;
      align-items: center;
      gap: 5px;

      svg:first-child {
        position: relative;
        top: 2px;
      }

      svg {
        cursor: pointer;
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
  }
}
