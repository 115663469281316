.map-container {
  width: 100%;
  height: calc(100vh - 280px);
  position: relative;
}

.open-marker {
  position: absolute;
  z-index: 50;
  transform: translate(-50%, -50%);

  .grid-container {
    display: grid;
    gap: 0.5rem; /* Tailwind's gap-2 */
  }

  .navigation-controls {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .counter {
      padding: 0.25rem 0.5rem; /* Tailwind's px-2 py-1 */
      font-size: 1rem; /* Tailwind's text-base */
      background-color: white;
      border-radius: 0.25rem; /* Rounded corners */
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Tailwind's shadow-lg */
    }

    .control-button {
      width: 2rem; /* Tailwind's w-8 */
      height: 2rem; /* Tailwind's h-8 */
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Tailwind's shadow-md */
    }
  }
}

.marker-button {
  border: 1px solid white;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  width: 36px; /* Tailwind's w-9 */
  height: 25px; /* Tailwind's h-[25px] */
  background-color: black;
  font-weight: 500; /* Tailwind's font-medium */
  font-size: 12px; /* Tailwind's text-xs */
}
