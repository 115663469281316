.modal-image {
  max-width: 600px;
  max-height: 600px;
}
.filter-icon {
  position: relative;
  margin-left: 4rem;
  text-align: center;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  // max-height: 100px;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  a:hover {
    background-color: #f1f1f1;
  }
}
.css-1nmdiq5-menu {
  overflow-y: auto;
  z-index: 9999;
}
.css-1nmdiq5-menu > div {
  z-index: 9999;
  max-height: 150px;
}
.react-datepicker__input-container {
  margin-left: 1rem;
}
.react-datepicker__input-container > input {
  &:hover {
    cursor: pointer;
  }
  align-items: center;
  cursor: default;
  margin-right: 1rem;
  display: flex;

  flex-wrap: wrap;

  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;

  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.SendButton {
  background-color: #41624c !important;
  border-color: #41624c !important;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  border-radius: 0.5rem;
}

.buttonDiv {
  width: 100%;
  display: flex;
  margin: 1rem 1rem 0rem 0rem;
  justify-content: flex-end;
}
.buttonDiv-pp {
  width: 100%;
  display: flex;
  margin: 1rem 1rem 0rem 1rem;
  justify-content: flex-end;
}
.css-13cymwt-control {
  max-height: 66px;
  min-height: unset;
  overflow: auto;
}
input[type="checkbox"] {
  accent-color: #006037;
  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;
}

.Vattenfall-mobile-title {
  // display: flex !important;
  // gap: 100px !important;

  flex-wrap: nowrap;

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }

  .Datefilter-flx-mobile-main {
    flex-wrap: nowrap !important;
  }

  .Datepicker {
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 576px) {
      flex-wrap: nowrap !important;
    }
  }

  .reactDatePicker {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        margin: 0;
      }
    }
  }
}

.row-mobile-collg8main {
  @media (max-width: 576px) {
    gap: 10px;
  }

  .collg3-mobile {
    @media (max-width: 576px) {
      padding: 0;
    }

    .Select-main {
      div {
        @media (max-width: 576px) {
          margin: 0;
        }
      }
    }
  }

  .col-lg-2 {
    @media (max-width: 576px) {
      padding: 0;
      margin: 10px 0 0 0;
    }
  }

  .project-Dropdown-sc {
    div {
      margin: 0;
    }
  }

  .collg4-mobile {
    @media (max-width: 576px) {
      padding: 0;
    }

    .project-Dropdown {
      .dropdown-align {
        div {
          @media (max-width: 576px) {
            margin: 0;
          }
        }
      }
    }
  }
}
.assignButtondesign {
  cursor: pointer;
  background-color: #41624c !important;
  border-color: #41624c !important;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  border: none;
  border-radius: 10px;
}
.edit-drop-down-main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  // backdrop-filter: blur(3px);
  background-color: rgb(0 0 0 / 58%);

  .edit-drop-down-content-main {
    width: fit-content;
    height: fit-content;

    gap: 10px;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px 20px;

    .edit-drop-down-content {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      border-bottom: 1px solid #b5b5c3;

      .header-text {
        font-size: 15px;
        font-weight: 500;
      }

      .fa-xmark {
        &::before {
          font-size: 25px !important;
        }
      }
    }

    .edit-drop-down-body {
      width: 500px;
      height: 470px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 10px 0;

      .input-search {
        padding: 0 10px 0;
        input[type="text"] {
          outline: none;
        }

        input {
          border: 1px solid #e1e1e1;
          padding: 0 13px;
          color: #848484;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          height: 32px;

          &:focus {
            opacity: none;
          }
        }
      }

      @media (max-width: 1024px) {
        width: 450px;
      }

      @media (max-width: 576px) {
        width: 300px;
      }

      .modalPlzAlign {
        margin: 10px 0;
        display: flex;
        gap: 10px;
        border-bottom: 1px solid #b5b5c3;
        flex-wrap: wrap;

        p {
          cursor: pointer;
          background-color: #006037;
          color: white;
          padding: 8px 10px;
          border-radius: 12px;
        }
      }

      .checckbox-alignmnent {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 10px;
        padding: 10px 0;

        input[type="checkbox"] {
          width: 16px;
          height: 16px;
          accent-color: #006037;
        }

        p {
          text-align: left;
          color: #000;
          font-weight: 600;
          font-size: 13px;
          margin: 0;
        }
      }
    }

    .edit-drop-down-pagination {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 35px;
      border-top: 1px solid #b5b5c3;
      padding: 10px 0 0 0;
      button {
        cursor: pointer;
        background-color: #006037;
        border: none;
        color: #fff;
        border-radius: 10px;
        padding: 8px 12px;
      }

      .butoon-alignment {
        display: flex;
        gap: 20px;
        .previus {
          cursor: pointer;
        }
        .next {
          cursor: pointer;
        }
      }
    }
  }
}

.eFEHrh div:first-child {
  width: 100%;
}

.vattenfall-title {
  @media (max-width: 576px) {
    flex-direction: column;
  }

  h2 {
    padding-left: 6px;

    @media (max-width: 576px) {
      padding-left: 0;
    }
  }
}
.new-deisgnpppppppppp {
  background-color: #f6eadf;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #e8a56a;
}

.new-deisgn-statusooooooooooo {
  background-color: #f6dfdf;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #d12424;
}

.newstatusppppppppppp {
  background-color: #e5f3ec;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #006037;
}

.all-lead-butoon {
  button {
    cursor: pointer;
    background-color: #006037;
    border: none;
    color: #fff;
    border-radius: 10px;
    padding: 8px 12px;
  }
}
.disable {
  button {
    cursor: pointer;
    background-color: #88a99b !important;
    border: none !important;
    color: #fff !important;
    border-radius: 10px !important;
    padding: 8px 12px !important;
  }
}

.wattfox-header-alignment {
  align-items: start;
  // justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
}

.mobile-view-alignment-main-parent {
  max-width: inherit !important;
  flex: 1 !important;
  @media (max-width: 1200px) {
    width: 100% !important;
    max-width: 100% !important;
  }

  @media (max-width: 576px) {
    padding: 0 !important;
  }

  .mobile-view-alignment-main {
    @media (max-width: 1200px) {
      margin: 0 !important;
    }

    .Datepicker {
      @media (max-width: 1440px) {
        min-width: 150px;

        .css-1n52tgk-control,
        .css-b62m3t-container {
          max-width: 100% !important;
          width: 100%;
        }
      }
      @media (max-width: 1430px) {
        min-width: auto !important;
      }
    }

    @media (max-width: 576px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .css-b62m3t-container {
        width: 100% !important;
      }

      .css-1n52tgk-control {
        min-width: 100% !important;
      }

      .css-14mj9xh-control {
        min-width: 100% !important;
      }

      .css-a33qwv-control {
        min-width: 100% !important;
      }

      .mobile-view-serch {
        grid-column: span 2;
      }
    }
  }
}

.mobile-view-alignment-main-parent-title {
  @media (max-width: 1200px) {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }
}
